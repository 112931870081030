import request from '@/utils/request'

export function GetSystemScore () {
    return request({
      url: '/api/Score/GetSystemScore'
    })
  }

export function GetMyScore (blockId) {
    return request({
      url: `/api/Score/GetMyScore?blockId=${blockId}`
    })
  }

export function getMyScoreList (params) {
    return request({
      url: '/api/Score/List',
      data: { ...params },
      method: 'post',
      header:{
        'Content-Type':'application/json;charset=utf-8'  //如果写成contentType会报错
      },
      
    })
  }

  export function getRankList (params,blockId) {
    return request({
      url: `/api/Score/RankList?blockId=${blockId}`,
      data: { ...params },
      method: 'post',
      header:{
        'Content-Type':'application/json;charset=utf-8'  //如果写成contentType会报错
      },
      
    })
  }