import request from "@/utils/request";

export function getLuckDraw(id) {
    return request({
        url: `/api/LuckDraw?id=${id}`
    })
}
export function doLuckDraw(id) {
    return request({
        url: `/api/LuckDraw/Draw?id=${id}`
    })
}

export function getRecordList  (obj) {
    return request.post("/api/LuckDraw/RecordList", obj);
}