<template>
  <div class="container">
    <div style="margin: auto; display: table">
      <div style="text-align: center; line-height: 50px">
        <span style="font-size: 16px; color: rgb(157, 29, 34)"
          >我的积分 {{ myscore }}</span
        >
      </div>
      <LuckyWheel
        v-if="luckDraw.showType != undefined && luckDraw.showType == 1"
        ref="myLucky"
        width="350px"
        height="350px"
        :prizes="LuckyWheel.prizes"
        :blocks="LuckyWheel.blocks"
        :buttons="LuckyWheel.buttons"
        @start="startCallback"
        @end="endCallback"
      />

      <LuckyGrid
        v-if="luckDraw.showType != undefined && luckDraw.showType == 2"
        ref="luckyGrid"
        width="350px"
        height="350px"
        :rows="grid.rows"
        :cols="grid.cols"
        :blocks="grid.blocks"
        :prizes="grid.prizes"
        :buttons="grid.buttons"
        :active-style="grid.activeStyle"
        @start="gridStart"
        @end="gridEnd"
      />

      <div style="line-height: 50px">
        抽奖结果 :
        <div style="text-align: center; height: 50px; line-height: 50px">
          <van-loading v-show="drawRecordLoading" size="24px"
            >抽奖中...</van-loading
          >
          <span
            style="font-size: 18px; color: rgb(157, 29, 34); font-weight: bold"
          >
            {{
              drawRecordShow.prizesName ? drawRecordShow.prizesName : " "
            }}</span
          >
        </div>
      </div>
      
      <div v-if="luckRecordList!=undefined && luckRecordList.length>0">
        <div class="notice">
          <div class="notice__inner">
            <div class="notice__box">
              <div class="notice__item" v-for="(item, index) in luckRecordList"
            :key="index">
            恭喜&nbsp;<span style="color: red">{{item.trueName}}</span
                >&nbsp;中奖 &nbsp;{{item.prizesName}}
              </div>
            
            </div>
            <div class="notice__box">
              <div class="notice__item" v-for="(item, index) in luckRecordList"
            :key="index">
                恭喜&nbsp;<span style="color: red">{{item.trueName}}</span
                >&nbsp;中奖 &nbsp;{{item.prizesName}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="acontent" v-html="luckDraw.detailsHtml"></div>
    </div>
  </div>
</template>
  
  <script>
import { getLuckDraw, doLuckDraw,getRecordList } from "@/api/luckdraw";
import { GetMyScore } from "@/api/score";

import { Toast } from "vant";
export default {
  data() {
    return {
      drawRecordLoading: false,
      luckid: "1535995B-0375-4E10-8BC7-781E60FF36B0",
      myscore: "-",
      drawRecord: [],
      drawRecordShow: [],
      luckprizes: {},
      luckindex: 0,
      luckDraw: {},
      LuckyWheel: {
        blocks: [{ padding: "20px", background: "#df1e15" }],
        prizes: [
          // { fonts: [{ text: '0', top: '10%' }], background: '#ffe8b5' ,imgs:[{src:'image/luckdraw/1.png',height:'95px'}] },
          // { fonts: [{ text: '1', top: '10%' }], background: '#ffd57c',imgs:[{src:'image/luckdraw/2.png',height:'95px'}] },
          // { fonts: [{ text: '2', top: '10%' }], background: '#ffb933',imgs:[{src:'image/luckdraw/3.png',height:'95px'}] },
          // { fonts: [{ text: '3', top: '10%' }], background: '#ffe8b5',imgs:[{src:'image/luckdraw/4.png',height:'95px'}] },
          // { fonts: [{ text: '4', top: '10%' }], background: '#ffd57c' ,imgs:[{src:'image/luckdraw/5.png',height:'95px'}]},
          // { fonts: [{ text: '5', top: '10%' }], background: '#ffb933',imgs:[{src:'image/luckdraw/6.png',height:'95px'}] },
        ],
        buttons: [
          {
            radius: "34%",
            background: "#8a9bf3",
            //pointer: true,
            //fonts: [{ text: '开始', top: '-10px' }],
            imgs: [
              {
                src: "image/luckdraw/start.png",
                height: "120px",
                top: "-64px",
              },
            ],
          },
        ],
      },
      grid: {
        rows: 3,
        cols: 3,
        blocks: [
          {
            padding: "20px",
            imgs: [
              {
                src: "image/luckdraw/bg2.png",
                width: "100%",
                height: "100%",
              },
            ],
          },
        ],
        prizes: [],
        buttons: [
          {
            x: 1,
            y: 1,
            background: "rgba(0,0,0,0)",
            imgs: [
              {
                src: "image/luckdraw/button.png",
                width: "100%",
                height: "100%",
              },
            ],
          },
        ],
        activeStyle: {
          background: "#FFDE2B",
        },
      },
      luckRecordPara: {
        current: 0,
        pageSize: 20,
        filterData: {
          luckDrawId: "",
          isLuck: true,
        },
      },
      luckRecordList:[],
    };
  },
  created() {
    let id = this.$route.query.id;
    if (id !== undefined) {
      this.luckid = id;
    }
    this.init();
    this.getRecord();
  },
  methods: {
    //
    async init() {
      let luckresult = await getLuckDraw(this.luckid);
      console.log(luckresult);
      if (!luckresult.data.success) {
        Toast("抽奖初始化失败，" + luckresult.data.errorMessage);
        return;
      }
      if (
        luckresult.data.data.prizesList == undefined ||
        luckresult.data.data.prizesList.lenght == 0
      ) {
        Toast("抽奖初始化失败，奖品信息不全");
      }
      this.luckDraw = luckresult.data.data;
      this.prizesList = luckresult.data.data.prizesList;
      luckresult.data.data.prizesList.forEach((element, index) => {
        let cindex = index % 3;
        let p = {};
        if (cindex == 0) {
          p = {
            background: "#ffe8b5",
            imgs: [{ src: element.imageUrl, height: "95px" }],
          };
        }
        if (cindex == 1) {
          p = {
            background: "#ffd57c",
            imgs: [{ src: element.imageUrl, height: "95px" }],
          };
        }
        if (cindex == 2) {
          p = {
            background: "#ffb933",
            imgs: [{ src: element.imageUrl, height: "95px" }],
          };
        }
        this.LuckyWheel.prizes.push(p);

        //
        let p2 = {};
        if (index < 3) {
          p2 = {
            x: index % 3,
            y: 0,
            fonts: [{ text: index }],
            background: "#ef7697",
            imgs: [{ src: element.imageUrl, height: "95px" }],
          };
        }
        if (index == 3) {
          p2 = {
            x: 0,
            y: 1,
            fonts: [{ text: index }],
            background: "#ef7697",
            imgs: [{ src: element.imageUrl, height: "95px" }],
          };
        }
        if (index == 4) {
          p2 = {
            x: 2,
            y: 1,
            fonts: [{ text: index }],
            background: "#ef7697",
            imgs: [{ src: element.imageUrl, height: "95px" }],
          };
        }
        if (index > 4) {
          p2 = {
            x: (index + 1) % 3,
            y: 2,
            fonts: [{ text: index }],
            background: "#ef7697",
            imgs: [{ src: element.imageUrl, height: "95px" }],
          };
        }
        this.grid.prizes.push(p2);
      });
      await this.getScore();
    },
    async getScore() {
      let scoreresult = await GetMyScore(0);
      if (scoreresult.data.success) {
        this.myscore = scoreresult.data.data.canUseScore;
      } else {
        this.myscore = "-";
      }
    },
    async getRecord() {
      this.luckRecordPara.filterData.luckDrawId=this.luckid;
      this.luckRecordPara.filterData.isLuck=true;
      let scoreresult = await getRecordList( this.luckRecordPara);
      if (scoreresult.data.data.length==0) {
        this.luckRecordPara.filterData.isLuck=false;
        scoreresult = await getRecordList( this.luckRecordPara);
      }
      this.luckRecordList=scoreresult.data.data;
    },

    // 点击抽奖按钮会触发star回调
    async startCallback() {
      this.$refs.myLucky.play();
      this.drawRecordLoading = true;
      this.luckindex = 0;
      this.drawRecordShow = {};

      let luckresult = await doLuckDraw(this.luckid);
      if (!luckresult.data.success) {
        Toast(luckresult.data.errorMessage);
        this.$refs.myLucky.init();
        this.drawRecordLoading = false;
      } else {
        // 调用抽奖组件的play方法开始游戏
        if (this.luckDraw.limitType == 1 && this.luckDraw.score > 0) {
          Toast("本次抽奖消耗 " + this.luckDraw.score + " 积分");
        }
        this.drawRecord = luckresult.data.data;
        this.prizesList.forEach((element, index) => {
          if (element.id == luckresult.data.data.luckDrawPrizesId) {
            this.luckindex = index;
            this.luckprizes = element;
          }
          setTimeout(() => {
            this.$refs.myLucky.stop(this.luckindex);
          }, 3000);
        });
      }
    },
    // 抽奖结束会触发end回调
    async endCallback(prize) {
      this.drawRecordShow = this.drawRecord;
      this.drawRecordLoading = false;
      console.log(prize);
      await this.getScore();
    },

    async gridStart() {
      this.$refs.luckyGrid.play();
      this.drawRecordLoading = true;
      this.luckindex = 0;
      this.drawRecordShow = {};

      let luckresult = await doLuckDraw(this.luckid);
      if (!luckresult.data.success) {
        Toast(luckresult.data.errorMessage);
        this.$refs.luckyGrid.init();
        this.drawRecordLoading = false;
      } else {
        // 调用抽奖组件的play方法开始游戏
        if (this.luckDraw.limitType == 1 && this.luckDraw.score > 0) {
          Toast("本次抽奖消耗 " + this.luckDraw.score + " 积分");
        }

        this.drawRecord = luckresult.data.data;
        this.prizesList.forEach((element, index) => {
          if (element.id == luckresult.data.data.luckDrawPrizesId) {
            this.luckindex = index;
            this.luckprizes = element;
          }
          setTimeout(() => {
            this.$refs.luckyGrid.stop(this.luckindex);
          }, 3000);
        });
      }
    },
    async gridEnd(prize) {
      this.drawRecordShow = this.drawRecord;
      this.drawRecordLoading = false;
      console.log(prize);
      await this.getScore();
    },
  },
};
</script>

<style scoped>
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  /*background-image: linear-gradient(to bottom, rgba(255,0,0,0), #ffc107);*/
}
.notice {
  width: 350px;
  height: 150px;
  border-radius: 8px;
  border: 1px solid #eee;
  margin: 10px auto;
}
.notice__inner {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 14px;
  color: #666;
}
.notice__box {
  animation: roll 10s linear infinite;
}
.notice__item {
  width: 100%;
  height: 30px;
  line-height: 30px;
  padding: 0 12px;
  white-space: nowrap;
}
@keyframes roll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-300px);
  }
}
</style>